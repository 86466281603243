body {
  background-color: rgb(245, 245, 245);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


header {
  position: fixed;
  display: block;
  width: 100%;
  background: black;
  color: white;
  height: 90px;
  top: 0px;
  z-index: 100;
  padding-bottom: 10px;
}

nav {
  position: absolute;
  top: 10%; left: 15px;
  transform: translateY(-10%);
}
nav, nav ul, nav ul li {
  margin: 0;
  padding: 0;
}
nav ul li {
  list-style-type: none;
  /* display: inline-block; */
  /* margin-right: 10px; */
  left: 20px;
}
.lastmenu {
  margin: 0;
}
nav ul li a {
  font-size: 19px;
  line-height: normal;
  color: white;
}

footer {
  background-color: black;
  color: white;

  position: fixed;
  bottom:0;
  width:100%;
  height:"20px";
  padding-left: 10px;
}

.app-footer {
  position: fixed;
  bottom:0;
  width:100%;
  height:"20px";
  padding-left: 10px;
}