

.title {
    background-color: slategrey;
    margin: auto;
    padding: 10px;
    font-size: large;
}

.content {
    background-color: aliceblue;
    font-size: medium;
    font-family: sans-serif;
    margin: auto;
    padding: 10px;
    padding-top: 40px;
    text-align: justify;
    text-justify: inter-word;
    width: auto;
    height: auto;
    position: absolute;
    left: 3px;
    right: 3px;
}

.email {
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;
}



.styled-table-contact {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    /* min-width: 400px; */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}



.styled-table-contact thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}


.styled-table-contact th,
.styled-table-contact td {
    padding: 12px 15px;
}

.styled-table-contact tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table-contact tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table-contact tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}


.styled-table-contact tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.img-ovi {
    margin: 3px;
    width: 220px;
    height: 350px;
    border: 5px solid #555;
}




@media (max-width: 500px) {
    /* For mobile phones: */
    /* [class*="styled-table"] {
      width: 300px;
    } */
    .styled-table-contact {
        width: 200px;
    }

    .img-ovi {
        margin: 3px;
        width: 150px;
        height: 250px;
        border: 5px solid #555;
    }
  }

  .wrapper-contacts {
    padding-top: 90px;
  }