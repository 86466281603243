

.title {
    background-color: slategrey;
    margin: auto;
    padding: 10px;
    font-size: large;
}

.content {
    background-color: aliceblue;
    font-size: medium;
    font-family: sans-serif;
    margin: auto;
    padding: 10px;
    padding-top: 40px;
    text-align: justify;
    text-justify: inter-word;
    width: auto;
    height: auto;
    position: absolute;
    left: 3px;
    right: 3px;
}







/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    right: 30px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    right: 30px;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }



  

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  /* min-width: 400px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  /* max-width: 500px; */
  /* width: 40%; */
  /* width: 400px; */
}



.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.ed th {
  background-color: #884b91;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.inactive-row {
  font-weight: bold;
  color: #393a31;
  background-color: rgb(235, 255, 255);
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  /* color: #009879; */
  color: #393a31;
  background-color: rgb(218, 252, 219);
}


@media (max-width: 500px) {
  /* For mobile phones: */
  /* [class*="styled-table"] {
    width: 300px;
  } */
  .styled-table {
      width: 300px;
  }
}

.home-container {
  margin-top: 90px;
}