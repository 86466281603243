

.title {
    background-color: slategrey;
    margin: auto;
    padding: 10px;
    font-size: large;
}

.content {
    background-color: aliceblue;
    font-size: medium;
    font-family: sans-serif;
    margin: auto;
    padding: 10px;
    padding-top: 20px;
    text-align: justify;
    text-justify: inter-word;
    width: auto;
    height: auto;
    position: absolute;
    left: 3px;
    right: 3px;
    padding-bottom: 50px;
}


.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    /* min-width: 400px; */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}



.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}


.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.inactive-row {
    font-weight: bold;
    color: #393a31;
    background-color: rgb(235, 255, 255);
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    /* color: #009879; */
    color: #393a31;
    background-color: rgb(218, 252, 219);
}

ul {
    list-style-type: disc;
}


@media (max-width: 500px) {
    /* For mobile phones: */
    /* [class*="styled-table"] {
      width: 300px;
    } */
    .styled-table {
        width: 300px;
    }
  }



  @media (max-width: 500px) {
    /* For mobile phones: */
    /* [class*="styled-table"] {
      width: 300px;
    } */
    .styled-table {
        width: 150px;
    }

  }



.wrapper-projects {
    padding-top: 90px;
}